/* Position and sizing of burger button */
.bm-burger-button {
  position: fixed;
  width: 30px;
  height: 25px;
  z-index: 9999 !important;
  right: 10%;
  top: 10px;
  transform: translate(0px, 0px);
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #044e11;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: #044e11;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #044e11;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
  height: 100%;
}

/* General sidebar styles */
.bm-menu {
  padding: 2.5em 0 0;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: "#044E11";
}

/* Wrapper for item list */
.bm-item-list {
  color: white;
  padding: 0.8em;
}

/* Individual item */
.bm-item {
  display: inline-block;
}

/* Styling of overlay */

@media screen and (min-width: 961px) {
  .bm-burger-button {
    display: none;
  }
}

.bm-overlay {
  background-color: #F6F5EA !important;
}
